/**
 * Returns a list (array) of states properties according to view
 *
 * @param {String} viewVariant id of the view
 * @returns {Object[]} Array of states
 */

const returnStates = (viewVariant, states, views) => {
  const viewType = views.some((view) => viewVariant === view.id) ? viewVariant : 'status-normal';
  const view = views.filter((v) => v.id === viewType);

  const { states: viewingStates } = Array.isArray(view) && view.length ? view[0] : [];
  const stateList = Array.isArray(viewingStates)
    ? viewingStates.map((s) => states.find((state) => state.id === s.id))
    : [];
  return stateList;
};

export default returnStates;
