import { useEffect, useState } from 'react';

const useContainerWidth = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      // Get the first observed element's content box size
      const { width } = entries[0].contentRect;
      setContainerWidth(width);
    });

    // Start observing the container
    resizeObserver.observe(containerRef.current);

    // Cleanup on unmount
    return () => resizeObserver.disconnect();
  }, []);

  return { containerWidth };
};

export default useContainerWidth;
